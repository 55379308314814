import React from 'react';
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import Blogrightsidebar from './Blogrightsidebar';
import ServiceData from '../Service/ServiceData';
import { findSpecific, findAll } from '../../services/blog.mjs'
import BlogsingleItem from './BlogsingleItem';
import Breadcrumb from '../Breadcrumb';

const Blogsingle =()=>{
    const { slug } = useParams();
    console.log('slug: ', slug)

    console.log('IN BLOG SINGLE')
    const [loading, setLoading] = useState(false)
    const [blogsdata, setBlogs] = useState([])
    const [blogsdataAll, setBlogsAll] = useState([])

    const fetchData = async () => {
        setLoading(true)
        const res = await findSpecific(slug)        
        const resAll = await findAll()        
        setBlogs([...res])
        setBlogsAll([...resAll])
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return(
        <div>
        { loading && 
            <p>loading...</p>
        }
        {blogsdata.length > 0 && blogsdata.map(blogitem => (
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle={blogitem.blog_title} Pdescription={blogitem.blog_summary} />    
        ))}
        
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog_sidebar_left">
                        <div className="blog_single mb_50">
                            
                            {blogsdata.length > 0 && blogsdata.map(blogitem => (
                                <BlogsingleItem blogitem={blogitem}/>
                            ))}
                            
                        </div>
                        {/* <div className="blog_post">
                            <div className="widget_title">
                                <h3 className="f_p f_size_20 t_color3">Related Post</h3>
                                <div className="border_bottom"></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="blog_post_item">
                                        <div className="blog_img">
                                            <img src={require('../../img/blog-grid/post_img_1.png')} alt=""/>
                                        </div>
                                        <div className="blog_content">
                                            <div className="entry_post_info">
                                                <a href=".#">March 14, 2018</a>
                                            </div>
                                            <a href=".#">
                                                <h5 className="f_p f_size_16 f_500 t_color">Why I say old chap that.</h5>
                                            </a>
                                            <p className="f_400 mb-0">Harry bits and bleeding crikey argy-bargy...</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="blog_post_item">
                                        <div className="blog_img">
                                            <img src={require('../../img/blog-grid/post_img_2.png')} alt=""/>
                                        </div>
                                        <div className="blog_content">
                                            <div className="entry_post_info">
                                                <a href=".#">April 14, 2017</a>
                                            </div>
                                            <a href=".#">
                                                <h5 className="f_p f_size_16 f_500 t_color">Bloke cracking goal the.</h5>
                                            </a>
                                            <p className="f_400 mb-0">Harry bits and bleeding crikey argy-bargy...</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="blog_post_item">
                                        <div className="blog_img">
                                            <img src={require('../../img/blog-grid/post_img_3.png')} alt=""/>
                                        </div>
                                        <div className="blog_content">
                                            <div className="entry_post_info">
                                                <a href=".#">March 15, 2016</a>
                                            </div>
                                            <a href=".#">
                                                <h5 className="f_p f_size_16 f_500 t_color">Oxford james bond.</h5>
                                            </a>
                                            <p className="f_400 mb-0">Harry bits and bleeding crikey argy-bargy...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        
                        
                    </div>
                    <div className="col-lg-4">
                        <Blogrightsidebar blogsdata={blogsdataAll}/>
                    </div>
                </div>
            </div>
        </section>
    </div>
    )
}
export default Blogsingle;