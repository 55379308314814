import React from 'react';
const ChatIntegration =()=>{
    return(
        <section className="security_integration_area chat_integration_area">
            <div className="container">
                <div className="border-bottom"></div>
                <div className="chat_title text-center">
                    <h2 className="wow fadeInUp"><span>Integrations.</span> Discover even more possibilities.</h2>
                    <p>Explore 20+ integrations that make your event management workflow more efficient and familiar</p>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/1.jpg")} alt="" width="150"/>
                            <h5>Google Analytics</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/2.jpg")} alt="" width="150"/>
                            <h5>Mailchimp</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/3.jpg")} alt="" width="150"/>
                            <h5>Google Sheets</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/4.jpg")} alt="" width="150"/>
                            <h5>Zoom</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/5.jpg")} alt="" width="150"/>
                            <h5>Facebook Pixel</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/6.jpg")} alt="" width="150"/>
                            <h5>Eventbrite</h5>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ChatIntegration;