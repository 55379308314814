import React from 'react';
import { Fade } from 'react-reveal';

const chatClients = () => {
    return (
        <div>
            {/* <section className="chat_clients_area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 p-0 chat_clients_feedback">
                            <div className="clients_feedback_item flex-row-reverse">
                                <div className="feedback_body">
                                    <p>
                                        Tidio has given our clients a quick and easy way to send over thoughts and questions without delay or wait time! No second guessing – just asked and answered quickly in order to make their purchasing decisions easier!
                                    </p>
                                    <div className="ratting">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <h3 className="f_name">John Doe</h3>
                                    <a href=".#">hello.com</a>
                                </div>
                                <img src={require("../img/home-chat/john.png")} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0 chat_clients_feedback feedback_two">
                            <div className="clients_feedback_item">
                                <div className="feedback_body">
                                    <p>
                                        Tidio has given our clients a quick and easy way to send over thoughts and questions without delay or wait time! No second guessing – just asked and answered quickly in order to make their purchasing decisions easier!
                                    </p>
                                    <div className="ratting">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <h3 className="f_name">Richard Tea</h3>
                                    <a href=".#">hello.com</a>
                                </div>
                                <img src={require("../img/home-chat/richard.png")} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="chat_get_started_area">
                <div className="container">
                    <div className="chat_get_started_content text-center">
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">Trusted by the best and loved by most</h2>
                        
                        <div className='d-flex justify-content-around flex-wrap'>
                        <div className="col-lg-2 col-md-2 col-sm-3">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/1.jpg")} alt="" width="100"/>
                            <h5>Google Analytics</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/2.jpg")} alt="" width="100"/>
                            <h5>Mailchimp</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/3.jpg")} alt="" width="100"/>
                            <h5>Google Sheets</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/4.jpg")} alt="" width="100"/>
                            <h5>Zoom</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/2.jpg")} alt="" width="100"/>
                            <h5>Mailchimp</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/3.jpg")} alt="" width="100"/>
                            <h5>Google Sheets</h5>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/co/4.jpg")} alt="" width="100"/>
                            <h5>Zoom</h5>
                        </a>
                    </div>



                        </div>
                    </div>
                </div>
            </section> */}

            <section className="seo_partner_logo_area sec_pad">
                <div className="container">
                    <div className="seo_sec_title text-center mb_70">
                        <Fade bottom><h2>Trusted by the best and loved by most</h2></Fade>
                    </div>
                    <div className="partner_logo_area_four">
                        <div className="row partner_info">
                            <div className="logo_item">
                                <a href=".#"><img src={require('../img/co/client/1.jpg')} alt="" /></a>
                            </div>
                            <div className="logo_item">
                                <a href=".#"><img src={require('../img/co/client/2.jpg')} alt="" /></a>
                            </div>
                            <div className="logo_item">
                                <a href=".#"><img src={require('../img/co/client/3.jpg')} alt="" /></a>
                            </div>
                            <div className="logo_item">
                                <a href=".#"><img src={require('../img/co/client/4.jpg')} alt="" /></a>
                            </div>
                            <div className="logo_item logonew">
                                <a href=".#"><img src={require('../img/co/client/5.png')} alt="" /></a>
                            </div>
                            {/* <div className="logo_item">
                                <a href=".#"><img src={require('../img/seo/logo_06.png')} alt="" /></a>
                            </div>
                            <div className="logo_item">
                                <a href=".#"><img src={require('../img/seo/logo_07.png')} alt="" /></a>
                            </div>
                            <div className="logo_item">
                                <a href=".#"><img src={require('../img/seo/logo_08.png')} alt="" /></a>
                            </div>
                            <div className="logo_item">
                                <a href=".#"><img src={require('../img/seo/logo_09.png')} alt="" /></a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="chat_get_started_area">
                <div className="container">
                    <div className="chat_get_started_content text-center">
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">Get started with us</h2>
                        <p>Explore a whole new world of possibilities with us, we’d love to hear from you and customize our offering to your requirements.</p>
                        <a href=".#" className="chat_btn btn_hover">Get Started</a>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default chatClients;