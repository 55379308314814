import { getDocs, collection, doc, getDoc, query, where } from "firebase/firestore"; 
import { getDb } from "../services/db.mjs"

const collection_name = "blogs"

export const findAll = async () => {
    console.log('DB RESULTS I am triggered')
    const doc_refs = await getDocs(collection(getDb(), collection_name))

    const res = []

    doc_refs.forEach(result => {
        res.push({
            // id: result.id, 
            ...result.data()
        })
    })
    console.log('DB RESULTS I am available', res)
    return res
}

export const findSpecific = async (slug) => {
    console.log('DB RESULTS specific I am triggered')
    const collection_ref = collection(getDb(), collection_name)
    const q = query(collection_ref, where("slug", "==", slug))
    const doc_refs = await getDocs(q);
    const res = []

    doc_refs.forEach(result => {
        res.push({
            id: result.id, 
            ...result.data()
        })
    })

    console.log('DB RESULTS specific I am available', res)
    return res
}