import React from 'react';
import Blogrightsidebar from './Blogrightsidebar';
import BlogListItemNew from './BlogListItemNew';
import ServiceData from '../Service/ServiceData';
import Breadcrumb from '../Breadcrumb';
import { useState, useEffect } from 'react'
import { findAll } from '../../services/blog.mjs'

const BlogGrid =()=>{

    const [loading, setLoading] = useState(false)
    const [blogsdata, setBlogs] = useState([])

    const fetchData = async () => {
        setLoading(true)
        const res = await findAll()        
        setBlogs([...res])
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return(
        <div>
        <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Coevento Blog" Pdescription="Sharing Knowledge and Insights on Top Trends in the Event Industry, Virtual Events and Metaverse"/>
        
        <section className="blog_area_two sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog_grid_info">
                        <div className="row">
                        { loading && 
                                <p>loading...</p>
                            }
                            
                            {blogsdata.length > 0 && blogsdata.map(blogitem => (
                                // blogitem.featured == 1 ? 
                                <BlogListItemNew blogitem={blogitem}/>
                                //  : ''                            
                            ))}
                            
                        </div>
                        {/* <ul className="list-unstyled page-numbers shop_page_number text-left mt_30">
                            <li><span aria-current="page" className="page-numbers current">1</span></li>
                            <li><a className="page-numbers" href=".#">2</a></li>
                            <li><a className="next page-numbers" href=".#"><i className="ti-arrow-right"></i></a></li>
                        </ul> */}
                    </div>
                    <div className="col-lg-4">
                        <Blogrightsidebar blogsdata={blogsdata}/>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}
export default BlogGrid;