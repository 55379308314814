// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

let db = false;

export const getDb = () => {
    if(!db){
        // Your web app's Firebase configuration
        const firebaseConfig = {
            apiKey: "AIzaSyALk7FCWhgx3lsnsYv2gzK_Wq7AKQ0a2r8",
            authDomain: "coevento-corp-website.firebaseapp.com",
            projectId: "coevento-corp-website",
            storageBucket: "coevento-corp-website.appspot.com",
            messagingSenderId: "638451796966",
            appId: "1:638451796966:web:ecdaaa45a724873b016427"
        };

        const app = initializeApp(firebaseConfig)

        db = getFirestore(app)
    }

    return db
}