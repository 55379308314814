import React from "react";
import HubspotForm from 'react-hubspot-form'

const ChatBanner = () => {
  return (
    <section className="chat_banner_area">
      <img
        className="p_absoulte cloud"
        data-parallax='{"x": 0, "y": 150}'
        src={require('../../img/home-chat/cloud.png')}
        alt=""
      />
      <img
        className="p_absoulte left wow fadeInLeft"
        data-wow-delay="0.2s"
        src={require('../../img/home-chat/left_leaf.png')} 
        alt=""
      />
      <img
        className="p_absoulte right wow fadeInRight"
        data-wow-delay="0.3s"
        src={require('../../img/home-chat/right_leaf.png')}
        alt=""
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="chat_banner_content">
              <div className="c_tag wow fadeInUp">
                <img src={require("../../img/home-chat/live_chat.png")} alt="" /> Conferences, Trade Shows, Exhibitions, Road Shows, Webinars, Product Launches and a lot more
              </div>
              <h2 className="wow fadeInUp" data-wow-delay="0.2s">
              The easiest way to host & manage all your <strong>Virtual Events</strong>
              </h2>
              <h6>To know more, please fill in the form below, we will call you back within 24 hrs :)</h6>
              <div
                className="text-center pr_100 wow fadeInUp"
                data-wow-delay="0.4s"
              >
                
               
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex">
            <div className="hubspot_form">
              <HubspotForm
                portalId='23800051'
                formId='f68a386b-4dca-44b0-8dc6-875a71fe267c'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
            />
                
            </div>
          </div>
          <div className="col-lg-3">
            <div className="chat_banner_content mb-4 pb-4">
              <div className="c_tag wow fadeInUp">
                
              </div>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> Completely customizable <strong>microsite</strong>
              </h6>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> Customized event areas - <strong>Lobby, Exhibition Hall, Auditorium</strong>
              </h6>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> 1:1 and group <strong>chat</strong>
              </h6>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> Gamification - <strong>quiz, break the glass, photo booth</strong>
              </h6>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> Real time <strong>notifications</strong>
              </h6>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> Pre-recorded and <strong>live videos</strong>
              </h6>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> Integration with <strong>Zoom</strong>
              </h6>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> Customized <strong>exhibition stalls</strong>
              </h6>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> Buyer Seller <strong>chat in sponsor booth</strong>
              </h6>
              <h6 className="wow fadeInUp" data-wow-delay="0.2s">
                <i class="fa fa-check mr-2"></i> Post-Event <strong>Analytics & Reporting</strong>
              </h6>
              <div
                className="text-center pr_100 wow fadeInUp"
                data-wow-delay="0.4s"
              >
                
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatBanner;
