import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';


const PrivacyPolicy = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar
                nClass="m-auto"
                hbtnClass="new_btn"
            />
            <section className="case_studies_area sec_pad">
                <div className="container">



                    <p>Coevento is an online event platform portal owned and operated by Tecvolo Labs Private Limited, a private company incorporated on 25 October 2016 and registered at Registrar of Companies, Gwalior. The Founder of Coevento is Ankita Mathur.</p>

                    <p>
                        Through the official website, https://coevento.today, the company will provide event-related information including Virtual Events as well as Hybrid Events covering diverse topics and themes. This Service may be availed by the User or their partners at any time throughout the year.</p>
                    <h2 className='text-start mt-3'>Privacy Policy</h2>
                    <hr className='w-25' />

                    <p>We ensure and respect your privacy along with all the information or data we collect on our website. Your data, information and privacy are secure with us.</p>

                    <h4>WE COLLECT:</h4>

                    <dl>
                        <dt>Your Log Data</dt>
                        <dd className="mb-4">We collect the log data as the user visits our website, which may include the user’s computer’s IP address, browser type, version, pages visited, date & time of visit, and duration on each page as well as other information that is allowed by the user’s web browser.</dd>

                        <dt>Your Personal Information</dt>
                        <dd className="mb-4">We may collect your Name, Email, Phone & Mobile Number, Date of Birth, Home & Mailing address, Work Address, Website Address, Payment Information, Social Media Profiles, etc.</dd>

                        <dt>Your Device Data</dt>
                        <dd className="mb-4">We collect the device data the user is using while accessing our websites such as the device type, operating system, unique device identifiers, device settings, and geo-location data. This also depends on the user’s device settings. The user checks the policies of their device manufacturer or software provider to know the information allowed to us.<br />
                            By visiting our website, our servers automatically log the data by the user’s web browser. We may collect your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visited, the time and date of your visits, the time spent on each page, and other information.
                        </dd>

                        <dt>Business Data</dt>
                        <dd className="mb-4">We may collect your Business data during the operation on our platform including transaction records, user profiles, analytics data, other metrics, stored files and other information as the users interact with our services.<br />
                            We process your personal information in a fair and transparent manner. We collect and process information about you only that you allow us to.
                        </dd>





                        <h6>For assurance, we collect and use your information only where:

                        </h6>
                        <ol>
                            <li className="mb-1">It’s really necessary for the performance of a contract or to take steps at your request before entering into such a contract as the services you request from our company.</li>

                            <li className="mb-1">You give us consent to do so for a specific purpose like you subscribed to our Newsletter or other Services and Updates.</li>


                            <li className="mb-1">Where you consent to our use of information about you for a specific purpose, you have the right to change your mind at any time for the new processing.</li>


                            <li className="mb-1">It fits a fair interest such as for research and development, to market and promotes our services, and to protect our legal rights and interests.</li>




                        </ol>
                    </dl>

                    <p>We will protect it within acceptable means to prevent loss and theft, also unauthorized access, disclosure, copying, use or modification. We advise that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security due to this fact. We may retain your personal information as required with a legal obligation and to protect your vital interests. We don’t store your information longer than necessary.
                    </p>

                    <h5 className='mt-4'>We Collect Information to</h5>

                    <p>We may collect, use, store and disclose information for the following purposes mentioned below. Your personal information will not be further processed in a manner that is incompatible with these purposes:</p>

                    <ul>
                        <li className='mb-1'>To provide you with our platform’s core features & services.</li>
                        <li className='mb-1'>To process any transactional or ongoing payments.
                        </li>
                        <li className='mb-1'>To contact, connect and communicate with you.</li>
                        <li className='mb-1'>To keep you updated with our services and offers.
                        </li>
                        <li className='mb-1'>To allow you to access and use our website and services.</li>
                        <li className='mb-1'>To offer additional benefits to you.</li>
                        <li className='mb-1'>For advertising and marketing purposes such as sending you promotional information about our products and services and third parties that may interest you.</li>
                        <li className='mb-1'>For Market research, business development and analytics.</li>
                        <li className='mb-1'>To manage and enhance our website, associated applications and social media platforms.</li>
                        <li className='mb-1'>To confirm our legal obligations and settle any disputes that may occur.</li>
                        <li className='mb-1'>To consider your employment application.
                        </li>
                        <li className='mb-1'>For internal records and administrative purposes.</li>
                    </ul>


                    <h5 className='mb-4'>Disclosure of personal information to Third-Parties</h5>

                    <p><strong>We may disclose personal information to:</strong></p>

                    <ul>
                        <li className='mb-1'>Third-Party service providers to enable them to provide their services, including IT service providers, data storage, hosting and server providers, debt collectors, maintenance or problem-solving providers, ad networks, analytics, error loggers, marketing or advertising providers, professional advisors and payment systems operators.
                        </li>
                        <li className='mb-1'>Our employees, contractors, related entities, sponsors or promoters of any competition we run.</li>
                    </ul>



                    <dt>CHOICE AND CONSENT:</dt>
                    <dd className="mb-4">You consent to us collecting, holding, using and disclosing your personal information in accordance with this privacy policy by providing your personal information to us. If you are under 18 years of age, you must have and warrant to the extent permitted by law to us, ensuring that you have your parent or legal guardian’s permission to access and use the website and consent to let you provide us with your personal information. It’s not compulsory to provide your personal information but it may affect the quick access and information we may provide you by using your information.
                    </dd>


                    <dt>INFORMATION FROM THIRD PARTIES:</dt>
                    <dd className="mb-4">If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such a person’s consent to provide the personal information to us.</dd>


                    <dt>RESTRICT:</dt>
                    <dd className="mb-4">You can restrict the use of your personal information if you need. You can alter the permissions anytime by contacting us. We will let you know how the restriction affects your use of our website or products and services.</dd>


                    <dt>ACCESS AND DATA PORTABILITY:</dt>
                    <dd className="mb-4">You may request your personal information or it’s copy if you want. You can also request us to remove the personal information we holdor if we need to transfer this personal information to another third party.
                    </dd>


                    <dt>CORRECTION DATA:
                    </dt>
                    <dd className="mb-4">If you believe that any information we hold about you is inaccurate, incomplete, misleading, out of date or irrelevant, please contact us and we will take reasonable steps to correct such information.
                    </dd>


                    <dt>COMPLAINTS:</dt>
                    <dd className="mb-4">If you believe that we have breached an acceptable data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps that will be taken to deal with your complaint right away.</dd>


                    <dt>UNSUBSCRIBE:</dt>
                    <dd className="mb-4">To unsubscribe from our e-mail database and updates, please contact us using the details below or unsubscribe using the unsubscribe or opt-out buttons provided.</dd>

                    <dt>Cookies</dt>
                    <dd className="mb-4">A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. We use “cookies” to collect information about you and your activity across our site. This helps us serve you content based on the preferences you have specified in our Cookie Policy.</dd>


                    <dt>Business Transfers</dt>
                    <dd className="mb-4">If we or our assets are acquired or enter bankruptcy, we would include data among the assets transferred to any parties who acquire us. You must acknowledge that such transfers may occur and any parties who acquire us can use your personal information.
                    </dd>


                    <dt>Limits of our policy</dt>
                    <dd className="mb-4">Our website may link to external sites that are not operated by us. Please note that we have absolutely no control over the content and policies of those sites, and cannot accept responsibility for their respective privacies.</dd>


                    <dt>Changes to this policy</dt>
                    <dd className="mb-4">We may change our privacy policy to reflect current acceptable practices. We will take suitable steps to let users know about changes via our website. The use of this site after any changes to this policy will be considered as acceptance of our practices around privacy and personal information.
                        We will ask you to re-consent the altered privacy policy in case we make a notable change to this privacy policy.
                    </dd>


                    <p><strong>Note: </strong>If you have any more questions about anything related to our company including events, registration, privacy policy, booking, or tickets, please <a href="#" data-bs-toggle="modal"
                        data-bs-target="#exampleModal11">Contact Us!</a></p>



                    <p><strong>Email - </strong><a href='mailto:support@tecvololabs.com'>support@tecvololabs.com</a></p>



                </div>

            </section>

            <FooterTwo fClass="pt_150" FooterData={FooterData} />
        </div>
    )
}
export default PrivacyPolicy;