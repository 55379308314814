import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import ChatBanner from "../components/Banner/ChatBanner";
import CoreFeatures from "../components/Features/CoreFeatures";
import ChatFeatures from "../components/Features/ChatFeatures";
import ChatIntegration from "../components/ChatIntegration";
import ChatClients from "../components/chatClients";
import FooterErp from "../components/Footer/FooterErp";
import FooterData from "../components/Footer/FooterData";
import FooterTwo from "../components/Footer/FooterTwo";

const homechat = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        nClass="m-auto"
        hbtnClass="new_btn"
      />
      <ChatBanner />
      <CoreFeatures />
      <ChatFeatures rowClass='flex-row-reverse' pClass="pr_70" col1="col-lg-6 offset-lg-1" col2="col-lg-5" icon="chat_icon.png" img1="dot.png" img2="section-3-menu.jpg" img3="homepage-section-3-login.jpg" titleSmall="Powerful event 
" titlebig="creation and management panel"
      p="With options to define all details like brochure, documents, sponsors, customize your microsite and many other things" url="#"/>


      <ChatFeatures  pClass="pl_70" col1="col-lg-6" col2="col-lg-5 offset-lg-1" icon="video_icon.png" img1="dot.png" img2="sectio-4-cts.jpg" img3="section-4-img2.jpg" titleSmall="Real-like" titlebig=" completely customizable virtual events"
      p="Global reach, advanced user engagement options of live poll, chat, breakout rooms, interactive booths, robust support" url="#"/>


      <ChatFeatures rowClass='flex-row-reverse' pClass="pr_60" col1="col-lg-6 offset-lg-1" col2="col-lg-5" icon="dashboard.png" img1="dot.png" img2="homepage-section-5-analytics.jpg" img3="section-5-analytics.jpg" titleSmall="Advanced " titlebig="Analytics"
      p="Event level, user level, booth level analytics with trends and engagement report" url="#"/>


      <ChatIntegration />

      <ChatClients/>
      <FooterTwo fClass="pt_150" FooterData={FooterData}/>
      
      {/* <FooterErp fClass="event_footer_area" FooterData={FooterData} /> */}
    </div>
  );
};
export default homechat;
