import React, {Component} from 'react';
class BlogGridItem extends Component{
    render(){        
        let BlogData = this.props.blogitem;
        return(
            <div className="col-lg-6">
                <div className="blog_list_item blog_list_item_two">
                    <a href="/#" className="post_date">
                        <h2>{BlogData.custom_date} <span>{BlogData.custom_month}</span></h2>
                    </a>
                    <a href={`/blog/${BlogData.slug}`}><img className="img-fluid" src={BlogData.blog_image} alt=""/></a>
                    <div className="blog_content">
                        <a href={`/blog/${BlogData.slug}`}>
                            <h5 className="blog_title">{BlogData.blog_title}</h5>
                        </a>
                        <p>{BlogData.blog_summary}</p>
                        <div className="post-info-bottom">
                            <a href={`/blog/${BlogData.slug}`} className="learn_btn_two">Read More <i className="arrow_right"></i></a>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BlogGridItem;