import React from "react";

const CoreFeatures = () => {
  return (
    <section className="chat_core_features_area sec_pad">
      <div className="container">
        <div className="hosting_title chat_title text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            Core Features
            <br /> That makes us <span>top notch</span>
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp">
              <div className="round">
                <div className="round_circle"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/pluse.png")}
                  alt=""
                />
                <img src={require("../../img/home-chat/chat.png")} alt="" />
              </div>
              <a href=".#">
                <h4>Elevated Audience Engagement</h4>
              </a>
              <p>
              Coevento lets you create engaging features of live chat, live poll, photo booth, one on one session scheduling and a lot more
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.2s">
              <div className="round">
                <div className="round_circle two"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/triangle.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/mobile.png')} alt="" />
              </div>
              <a href=".#">
                <h4>Customized Mobile App</h4>
              </a>
              <p>
              For all your delegates, it allows for a simple and easy way to access information and also connect to various meeting rooms

              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.4s">
              <div className="round">
                <div className="round_circle three"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/box.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/book.png')} alt="" />
              </div>
              <a href=".#">
                <h4>All-in-one Solution</h4>
              </a>
              <p>
              Making your entire event management a breeze by powerful interface offering customization, team collaboration and real time status

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CoreFeatures;
