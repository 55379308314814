import React, {Component} from 'react';
class BlogsingleItem extends Component {
    
    render(){
        let BlogData = this.props.blogitem;
        const tags = BlogData.tags.split(',')
        const url = window.location.href
        return(
            <>
            <div key={BlogData.id}>
                <img className="img-fluid" src={BlogData.blog_image} alt=""/>
                <div className="blog_content">
                    <div className="post_date">
                        <h2>{BlogData.custom_date} <span>{BlogData.custom_month}</span></h2>
                    </div>
                    <a href={`/blog/${BlogData.slug}`}>
                        <h5 className="f_p f_size_20 f_500 t_color mb-30">{BlogData.blog_title}</h5>
                    </a>
                    <div dangerouslySetInnerHTML={{ __html: BlogData.blog_description }} />
                    <div className="post_share">
                        <div className="post-nam"> Share: </div>
                        <div className="flex">
                            <a target="_blank" href={`https://www.facebook.com/sharer.php?u=${url}`}><i className="ti-facebook"></i>Facebook</a>
                            <a target="_blank" href={`https://twitter.com/share?url=${url}&text=${BlogData.blog_title}&via=[via]&hashtags=coevento,virtual events,event platform,metaverse`}><i className="ti-twitter"></i>Twitter</a>
                            <a target="_blank" href={`https://www.linkedin.com/shareArticle?url=${url}&title=${BlogData.blog_title}`}><i className="ti-linkedin"></i>LinkedIn</a>
                            <a target="_blank" href={`https://api.whatsapp.com/send?text=${BlogData.blog_title} ${url}`}><i className="ti-whatsapp"></i>WhatsApp</a>
                        </div>
                    </div>
                    <div className="post_tag d-flex">
                        <div className="post-nam"> Tags: </div>
                        {tags.map(tag => (
                            <a href=".#">{tag}</a>
                        ))}
                    </div>
                    
                </div>
            </div>
            </>
        )

    }
}
export default BlogsingleItem;